import Head from '../Head'

export default function GrantsHead() {
  return (
    <Head
      title="Decentraland DAO - Grants"
      description="The Decentraland DAO grants program is a mechanism voted by the community that allows any person to request funding to make contributions to Decentraland."
    />
  )
}
