import GrantsHead from '../../components/Grants/Head'
import GrantsFooter from '../../components/GrantsFooter/GrantsFooter'
import { External } from '../../components/Icon'
import GrantsNavigation from '../../components/Navigation/GrantsNavigation'
import header_resources from '../../images/grants/header_resources.webp'

import './grants.css'

export default function GrantsResourcesPage() {
  return (
    <>
      <GrantsHead />

      <GrantsNavigation activeTab="/grants/resources" />

      <div className="grantsHeader">
        <div className="grantsHeader__container">
          <div className="grantsHeader__leftPanel">
            <div className="grantsHeader__title">Resources Library</div>
            <div className="grantsHeader__text">
              A comprehensive list of helpful links, contact information, and calendar events.
            </div>
          </div>
          <img className="grantsHeader__logo" src={header_resources} alt="Grants resources" />
        </div>
      </div>

      <div className="resources__Container">
        <div className="resourcesSection__title">Resources for grantees</div>
        <div className="resourcesSection__cardsContainer resourcesSection__cardsContainer--wrap">
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="https://docs.decentraland.org/player/general/dao/overview/grants-faq/"
          >
            FAQs for Grantees
            <External black />
          </a>
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="https://calendar.google.com/calendar/u/0/r?cid=Y18yNGU1ZDgzOGNiMmI1ZDdiZjk2NzczOGY2ZmU0ZjYxZTgxNWYzYjgzYzg2MWEyNjI3MDU0ODM3YTJhMzE1NDNiQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20"
          >
            Subscribe to the DAO Calendar
            <External black />
          </a>
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="https://docs.decentraland.org/player/general/dao/overview/security-recommendations/"
          >
            Read the Web3 Security Guide
            <External black />
          </a>
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="https://drive.google.com/drive/folders/1vKxVfXb33z7xaDqhqWV1yCWOI8339ba-?usp=sharing"
          >
            Brand Assets for grantees use
            <External black />
          </a>
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="https://www.notion.so/dcl-dao/Public-DAO-Grant-s-Testing-Sessions-9148a72be11841e987b8a43e0eca1c16?pvs=4"
          >
            Participate in Testing Sessions
            <External black />
          </a>
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="mailto://fifi.real@decentraland.org"
          >
            Book your Testing Session
            <External black />
          </a>
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="https://calendly.com/gssquad/town-hall-grant-updates"
          >
            Book your Townhall Update
            <External black />
          </a>
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="https://forms.gle/8DrC151BZwPyoptV6"
          >
            Request promotion for your grant
            <External black />
          </a>
          <a
            className="resourcesSection__card--horizontal"
            rel="noreferrer"
            target="_blank"
            href="https://forms.gle/ysbsMnvJ2RSBn3Vx7"
          >
            Formal request to raise Decentraland grants concerns
            <External black />
          </a>
        </div>

        <div className="resourcesSection__title">Who can I ask for help?</div>
        <div className="resourcesSection__subTitle">Squads</div>
        <div className="resourcesSection__cardsContainer">
          <div className="resourcesSection__card">
            <div>
              <div className="resourcesSection__card--title">Grant Support Squad (GSS)</div>
              <div className="resourcesSection__card--text">
                This will be your main point of contact, and we encourage you to reach out to them! They provide support
                to the community members who receive grants, foster transparency in the grants ecosystem and take care
                of the DAO treasury, with the main objective of guaranteeing successful projects from the grants
                program.
              </div>
            </div>
            <a
              className="cta inverted resourcesSection__cta"
              target="_blank"
              rel="noreferrer"
              href="mailto://palewin@decentraland.org"
            >
              CONTACT
              <External />
            </a>
          </div>
          <div className="resourcesSection__card">
            <div>
              <div className="resourcesSection__card--title">Governance Squad</div>
              <div className="resourcesSection__card--text">
                Its goal is to develop, enhance and maintain the technological infrastructure used by the DAO members to
                make decisions about the Decentraland project like the dApp that we use to vote all the governance
                proposals.
              </div>
            </div>
            <a
              className="cta inverted resourcesSection__cta"
              target="_blank"
              rel="noreferrer"
              href="mailto://gino@decentraland.org"
            >
              CONTACT
              <External />
            </a>
          </div>
          <div className="resourcesSection__card">
            <div>
              <div className="resourcesSection__card--title">Facilitation Squad</div>
              <div className="resourcesSection__card--text">
                A team that fosters a welcoming community, providing support and orientation and moderating discussions
                for a positive atmosphere. They optimize governance operations for transparency and efficiency and
                manage social media to foster strategic communication among the DAO, community, and external
                stakeholders.
              </div>
            </div>{' '}
            <a
              className="cta inverted resourcesSection__cta"
              target="_blank"
              rel="noreferrer"
              href="mailto://federico.holzwarth@decentraland.org"
            >
              CONTACT
              <External />
            </a>
          </div>
        </div>
        <div className="resourcesSection__subTitle">Committees</div>
        <div className="resourcesSection__cardsContainer">
          <div className="resourcesSection__card">
            <div>
              <div className="resourcesSection__card--title">DAO Committee</div>
              <div className="resourcesSection__card--text">
                A group of three trusted individuals who have been selected by the community to hold keys in a multi-sig
                wallet. This multi-sig is responsible for enacting any passed votes with a binding action, like funding
                a Grant, banning a name, adding or removing a POI, implementing a Governance proposal or adding a
                Catalyst node.
              </div>
            </div>
            <a
              className="cta inverted resourcesSection__cta"
              target="_blank"
              rel="noreferrer"
              href="https://decentraland.org/governance/transparency/#:~:text=Ariel-,DAO%20Committee,-Kyllian"
            >
              MEMBERS
              <External />
            </a>
          </div>
          <div className="resourcesSection__card">
            <div>
              <div className="resourcesSection__card--title">Revocations Committee</div>
              <div className="resourcesSection__card--text">
                A group of four trusted individuals (one is a substitute) who have been selected by the community to
                revoke grants that raise concerns. The team is responsible for analyzing cases received from the Grant
                Support Squad, acting as a third party not involved in the process of revision of concerns.
              </div>
            </div>{' '}
            <a
              className="cta inverted resourcesSection__cta"
              target="_blank"
              rel="noreferrer"
              href="https://decentraland.org/governance/transparency/#:~:text=Revocation-,Committee,-dax"
            >
              MEMBERS
              <External />
            </a>
          </div>
          <div className="resourcesSection__card">
            <div>
              <div className="resourcesSection__card--title">Curators Committee</div>
              <div className="resourcesSection__card--text">
                Responsible for reviewing new wearable submissions ensuring they are glitch-free and compliant with the
                design guidelines. They also rise warnings about IP infringement and violent content.
              </div>
            </div>{' '}
            <a
              className="cta inverted resourcesSection__cta"
              target="_blank"
              rel="noreferrer"
              href="https://decentraland.org/governance/transparency/#:~:text=Wearable%20Curation%20Committee"
            >
              MEMBERS
              <External />
            </a>
          </div>
        </div>
        <div className="resourcesSection__subTitle">General Support</div>
        <div className="resourcesSection__cardsContainer">
          <div className="resourcesSection__card">
            <div>
              <div className="resourcesSection__card--title">Players and Creators Support</div>
              <div className="resourcesSection__card--text">
                The scope of the support team is to support all users of the platform; users, players, content creators.
                When more technical issues are encountered (SDK for example, we consult the teams within the
                foundation).
              </div>
            </div>{' '}
            <a
              className="cta inverted resourcesSection__cta"
              target="_blank"
              rel="noreferrer"
              href="https://intercom.decentraland.org/"
            >
              CONTACT
              <External />
            </a>
          </div>
          <div className="resourcesSection__card">
            <div>
              <div className="resourcesSection__card--title">Decentraland Studios</div>
              <div className="resourcesSection__card--text">
                A DAO initiative designed to bridge the gap between creatives and brands in Decentraland. They serve as
                a hub for talented creative studios working within Decentraland, offering a platform to showcase their
                projects. They also maintain an exclusive job board for vetted studios. If you need expert assistance in
                Decentraland, this is the place to turn your vision into reality.
              </div>
            </div>
            <a
              className="cta inverted resourcesSection__cta"
              target="_blank"
              rel="noreferrer"
              href="https://studios.decentraland.org"
            >
              CONTACT
              <External />
            </a>
          </div>
        </div>
      </div>
      <GrantsFooter />
    </>
  )
}
